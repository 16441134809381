<template>
    <component
        :is="is"
        type="button"
        class="relative flex h-fit flex-1 justify-center rounded-md font-medium shadow-sm"
        :class="[
            {
                'py-1 px-3 text-xs': size === 'small',
                'py-1 px-2 text-sm': size === 'medium-small',
                'py-2 px-4 text-sm': size === 'medium',
                'py-3 px-6 text-base': size === 'large',

                'w-full': expanded,
                'w-max max-w-fit': !expanded,

                ...(!outline && {
                    ...(disabled && {
                        'cursor-auto border border-primary-opacity-10 bg-primary-opacity-70 text-primary-bw-opacity-55 hover:bg-primary-opacity-70': true,
                        'bg-red-300 text-light hover:bg-red-300 hover:text-light':
                            danger,
                    }),
                    ...(!disabled && {
                        'border border-primary-bw-opacity-25 bg-primary text-primary-bw': true,
                        'bg-red-500 text-light hover:bg-red-600 hover:text-light':
                            danger,
                    }),
                }),

                ...(outline && {
                    ...(disabled && {
                        'border-2 border-primary-opacity-10 bg-gray-100 text-dark-40': true,
                        'border-red-200 bg-transparent text-red-300': danger,
                    }),
                    ...(!disabled && {
                        'border-2 border-primary bg-transparent text-dark': true,
                        'hover:bg-primary hover:text-primary-bw': !danger,
                        'border-red-500 text-red-600 hover:bg-red-500 hover:text-light':
                            danger,
                    }),
                }),

                'border-2 border-primary-opacity-10 bg-primary-opacity-10 text-dark':
                    faded,
            },
        ]"
        :disabled="disabled || loading"
    >
        <span
            v-if="icon"
            class="absolute inset-y-0 left-0 flex items-center pl-3"
        >
            <GFIcon
                :icon="icon"
                :class="iconClasses"
                class="text-primary-bw-opacity-40"
            />
        </span>
        <div
            class="relative flex items-center justify-center whitespace-nowrap"
        >
            <div
                v-if="loading"
                class="absolute flex h-full w-full items-center justify-center text-primary-bw-opacity-30"
            >
                <GFSpinner
                    :class="{
                        'h-4 w-4': size === 'small',
                        'h-5 w-5': size === 'medium-small',
                        'h-6 w-6': size === 'medium',
                        'h-8 w-8': size === 'large',
                    }"
                />
            </div>
            <span
                :class="[!!icon && !expanded && 'ml-4', loading && 'invisible']"
            >
                <slot>
                    {{ label }}
                </slot>
            </span>
        </div>
    </component>
</template>
<script lang="ts" setup>
import { defineComponent, type PropType } from "vue"
import GFIcon from "./GFIcon.vue"
import GFSpinner from "./GFSpinner.vue"

defineComponent({
    name: "GFButton",
})

defineProps({
    label: {
        type: String,
        default: null,
    },

    disabled: {
        type: Boolean,
        default: false,
    },

    expanded: {
        type: Boolean,
        default: false,
    },

    loading: {
        type: Boolean,
        default: false,
    },

    outline: {
        type: Boolean,
        default: false,
    },

    /**Cannot be used with danger or outline style */
    faded: {
        type: Boolean,
        default: false,
    },

    danger: {
        type: Boolean,
        default: false,
    },

    size: {
        type: String as PropType<"small" | "medium" | "large" | "medium-small">,
        default: "medium",
    },

    icon: {
        type: String,
        default: null,
    },

    iconClasses: {
        type: String,
        default: null,
    },
    is: {
        type: String as PropType<"button" | "a">,
        default: "button",
    },
})
</script>
<style scoped>
.gf-leading {
    line-height: 1.37rem;
}

.form .expiry input {
    min-width: 0;
}
</style>
